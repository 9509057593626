<template>
    <NuxtLayout name="c-root">
        <NuxtLoadingIndicator color="#E4012C" />
        <NuxtRouteAnnouncer />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>

        <!--  Notifications  -->
        <CoreNotifications
            :text-color="{
                success: '#00a64f',
            }"
        />
    </NuxtLayout>
</template>

<script lang="ts" setup>

usePageAttrsManager()

</script>
