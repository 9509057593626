
// @ts-nocheck


export const localeCodes =  [
  "cs"
]

export const localeLoaders = {
  "cs": [{ key: "../../../../../../shared/core-simploshop/i18n/locales/cs-CZ.json5", load: () => import("../../../../../../shared/core-simploshop/i18n/locales/cs-CZ.json5" /* webpackChunkName: "locale__opt_simploshop_shared_core_simploshop_i18n_locales_cs_CZ_json5" */), cache: true },
{ key: "../../../../../../shared/core-theme/i18n/locales/cs-CZ.json5", load: () => import("../../../../../../shared/core-theme/i18n/locales/cs-CZ.json5" /* webpackChunkName: "locale__opt_simploshop_shared_core_theme_i18n_locales_cs_CZ_json5" */), cache: true },
{ key: "../../../../i18n/locales/cs-CZ.json5", load: () => import("../../../../i18n/locales/cs-CZ.json5" /* webpackChunkName: "locale__opt_simploshop_themes_vut_i18n_locales_cs_CZ_json5" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../../../../../../shared/core-simploshop/i18n/i18n.config.ts?hash=82a408c4&config=1" /* webpackChunkName: "i18n_config_82a408c4" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": "default",
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": false,
    "onlyLocales": [
      "cs"
    ]
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "cs",
      "language": "cs-CZ",
      "name": "Česky",
      "flag": "/images/flags/flag-cz.webp",
      "flagAlt": "Česká vlajka",
      "domainDefault": true,
      "domain": "https://vut.dev.simploshop.com",
      "files": [
        "/opt/simploshop/shared/core-simploshop/i18n/locales/cs-CZ.json5",
        "/opt/simploshop/shared/core-theme/i18n/locales/cs-CZ.json5",
        "/opt/simploshop/themes/vut/i18n/locales/cs-CZ.json5"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "https://vut.dev.simploshop.com",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "language": "cs-CZ",
    "name": "Česky",
    "flag": "/images/flags/flag-cz.webp",
    "flagAlt": "Česká vlajka",
    "domainDefault": true,
    "domain": "https://vut.dev.simploshop.com",
    "files": [
      {
        "path": "/opt/simploshop/shared/core-simploshop/i18n/locales/cs-CZ.json5"
      },
      {
        "path": "/opt/simploshop/shared/core-theme/i18n/locales/cs-CZ.json5"
      },
      {
        "path": "/opt/simploshop/themes/vut/i18n/locales/cs-CZ.json5"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
